export const MenuItems = [
    {
        title: 'About Me',
        url: '/',
        cName: 'nav-links'

    },
    {
        title: 'Projects',
        url: '/projects',
        cName: 'nav-links'

    }
]